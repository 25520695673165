import * as React from 'react';
import { useEffect, useRef } from 'react';

export const SigmaVideo = (props: React.PropsWithChildren<any>) => {
    const ref = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        ref.current?.play;
    });
    return (
        <video
            ref={ref}
            className="shadow-2xl rounded-full mx-auto"
            style={{ width: '180px' }}
            autoPlay={true}
            muted={true}
            controls={false}
            loop={true}
            playsInline={true}
            onContextMenu={(e) => e.preventDefault()}
        >
            {props.children}
        </video>
    )
}



