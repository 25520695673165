import { Link } from 'gatsby';
import React from 'react';

export const GoTo = (
  props: React.PropsWithChildren<{
    to?: string;
    hashState?: string;
    isExtern?: boolean;
  }>
) => {
  const link = !props.isExtern ? (
    <Link
      to={props.to ? `/${props.to}` : '/'}
      state={{ prevPath: props.hashState }}
      className="text-red font-medium text-2xl lg:text-4xl no-underline inline-flex mt-16"
    >
      <div>
        <span className="mt-8 text-left">{props.children}</span>
      </div>

      <div>
        <span
          style={{
            maxWidth: '80px',
            maxHeight: '80px',
            width: '80px',
            height: '80px',
          }}
          className="sigma-arrow left"
        ></span>
      </div>
    </Link>
  ) : (
    <a
      href={props.to}
      className="text-red font-medium text-2xl lg:text-4xl no-underline inline-flex mt-16"
    >
      <div>
        <span className="mt-8 text-left">{props.children}</span>
      </div>

      <div>
        <span
          style={{
            maxWidth: '80px',
            maxHeight: '80px',
            width: '80px',
            height: '80px',
          }}
          className="sigma-arrow left"
        ></span>
      </div>
    </a>
  );

  return link;
};
