import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

export interface ITypedTextProps {
  elementId?: string;
  delayedStart?: boolean;
  loop?: boolean;
  className?: string;
}

const TypedText = ({ loop, elementId, className, delayedStart = false }: ITypedTextProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  let typedJs: Typed;

  const createTypedJs = () => {
    const options = {
      stringsElement: elementId,
      typeSpeed: 40,
      backSpeed: 10,
      loop: loop,
      showCursor: false,
    };
    let typedJS = new Typed(ref.current as any, options);
    return typedJS;
  };
  let observer: any;

  useEffect(() => {
    if (delayedStart) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio >= 0.5) {
            if (!typedJs) {
              typedJs = createTypedJs();
            }
          }
        },
        { root: null, rootMargin: '0px', threshold: 0.5 }
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
    } else {
      typedJs = createTypedJs();
    }
    return () => {
      if (typedJs) {
        typedJs.destroy();
      }
      if (delayedStart) {
        observer.disconnect();
      }

    };
  }, []);

  return <span className={className} ref={ref}></span>;
};
export default TypedText;
