import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GoTo } from '../components/shared/go-to';
import { SigmaVideo } from '../components/shared/sigma-video';
import TypedText from '../components/shared/typed-text';
import beerpong_vid from '../images/videos/Bierpong_x264.mp4';
import flip_cup_vid from '../images/videos/Flip Cup_x264.mp4';
import rage_cage_vid from '../images/videos/Rage Cage_x264.mp4';

export const query = graphql`
  query {
    whatToDrinkImg: file(relativePath: { eq: "what_to_drink_img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whoWeAreImg: file(relativePath: { eq: "who_we_are.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bar: file(relativePath: { eq: "bar_background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mapImg: file(relativePath: { eq: "map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cupImg: file(relativePath: { eq: "download_cup_big.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

interface IIndexPageState {
  isGdpr: boolean;
}

interface IIndexPageProps {
  data: any;
}

export class IndexPage extends React.Component<
  IIndexPageProps,
  IIndexPageState
> {
  constructor(props: IIndexPageProps) {
    super(props);
    this.state = {
      isGdpr: false,
    };
  }

  render() {
    return (
      <Layout isHome={true}>
        <SEO
          keywords={[
            `Sigma Bar Würzburg`,
            `Würzburg`,
            `Bar`,
            `Trinkspiele`,
            `Vorglühen`,
            `American Student Bar`,
            `Studenten`,
            `Party Würzburg`,
            `Bars in Würzburg`,
          ]}
          title="Cheers"
        />

        <section className="container mt-28" id="barsigma">
          <div className="header">
            <h1>
              BAR SIGMA: <br className="sm:hidden" />
            </h1>
            <span className="h-44 block font-calk  text-4xl sm:text-5xl">
              <div id="intro-typed-text" className="invisible">
                <span>
                  [PRE]GAMES.
                  <br />
                </span>
                <span>
                  PLACE TO BE WUERZBURG.
                  <br />
                </span>
                <span>
                  NEW FRIENDS.
                  <br />
                </span>
                <span>
                  MEXIKANER.
                  <br />
                </span>
                <span>
                  FUN TIMES.
                  <br />
                </span>
                <span>
                  CHEAP THRILLS.
                  <br />
                </span>
              </div>
              <TypedText
                loop={true}
                elementId={'#intro-typed-text'}
              ></TypedText>
            </span>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1">
            <div className="col-span-full mx-auto content-padding text-justify">
              American Students machen es uns schon lange vor: Eine Party mit
              guter Musik, Drinks aus Red Solo Cups und Beer Pong. Dein Problem:
              Keine:r deiner Freund:innen will die eigene Bude zur Verfügung
              stellen. Deine Lösung: SIGMA. Wir sorgen für Drinks zu fairen
              Preisen, zeigen euch die Top Games mit den original Solo Cups und
              versetzen euch in eine College-Party. Du bist neu in der Stadt und
              willst Leute kennenlernen? Du willst deine Freund:innen zu einem
              Bier Pong-Turnier herausfordern? Du hast ein Date und willst beim
              Flip Cup direkt sehen, ob es girl- or boyfriend-Material ist? Come
              in and find out. Denn wie einst Miley Cyrus schon sagte: It’s a
              party in the USA.
            </div>
          </div>
        </section>

        <section id="whatToDrink" className="mt-44 relative">
          <div className="header container">
            <h1>
              <span className="relative z-10">WHAT TO</span>

              <span className="text-highlight"> DRINK.</span>
            </h1>
          </div>
          <div className="container full grid lg:grid-cols-8 grid-cols-1 gap-4">
            <div className="lg:col-span-3 col-span-12">
              <Img
                className=" left-0 max-w-xl w-full"
                fluid={this.props.data.whatToDrinkImg.childImageSharp.fluid}
                alt="What To Drink"
              />
            </div>
            <div className="lg:col-span-2 col-span-12 lg:self-center">
              <div className="drinks-loop mx-auto md:mx-0 overflow-hidden font-calk text-left leading-normal">
                <div
                  className="origin-top-left mt-20 h-72 sm:h-96 leading-relaxed"
                  style={{ transform: 'rotate(-25deg)' }}
                >
                  <div id="what-to-drink-typings" className="invisible">
                    <span>
                      BEER.
                      <br /> FIREBALL.
                      <br /> GIN TONIC.
                      <br /> ...
                    </span>
                  </div>
                  <TypedText
                    loop={true}
                    elementId="#what-to-drink-typings"
                  ></TypedText>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 items-center col-span-12 flex justify-center">
              <a
                href={
                  'static/4c3a668a39a5833e82d021fb64a3fe97/Getränkekarte_SIGMA.pdf'
                }
                key={'getraenkekarte'}
                download
                className="inline-flex flex-col no-underline "
              >
                <Img
                  fixed={this.props.data.cupImg.childImageSharp.fixed}
                  className="mx-auto"
                  alt="SIGMA Cup Download Menu"
                />
                <span className="hover:text-red underline  pt-4 font-normal text-3xl">
                  Let me see what you got.
                </span>
              </a>
            </div>

            <div className="col-span-12 container text-right">
              <GoTo to="morethanrussianstandard" hashState="whatToDrink">
                <span className="z-10 relative">
                  btw. Hier erfahrt ihr alles über unseren
                </span>
                <span className="text-highlight white">
                  eigenen SIGMA Wodka. Click here.
                </span>
              </GoTo>
            </div>
          </div>
        </section>

        <section id="whatToPlay" className="mt-44">
          <div className="container">
            <div className="header">
              <h1>WHAT TO PLAY.</h1>
            </div>
            <div className="lg:col-span-12 grid grid-cols-1 lg:grid-cols-3 gap-x-12 gap-y-14">
              <div className="text-center px-2">
                <SigmaVideo>
                  <source src={beerpong_vid} type="video/mp4" />
                </SigmaVideo>

                <h2 className="mt-6 font-calk">BEER PONG.</h2>
              </div>
              <div className="text-center px-2">
                <SigmaVideo>
                  <source src={flip_cup_vid} type="video/mp4" />
                </SigmaVideo>
                <h2 className="mt-6 font-calk">FLIP CUP.</h2>
              </div>
              <div className="text-center px-2">
                <SigmaVideo>
                  <source src={rage_cage_vid} type="video/mp4" />
                </SigmaVideo>
                <h2 className="mt-6 font-calk">RAGE CAGE.</h2>
              </div>
            </div>
            <div className="lg:col-span-12 content-padding lg:col-start-3 mt-16 text-justify">
              Ihr wollt eure Woche bei ein paar Drinks ausklingen lassen? Klar,
              machen wir auch. Aber etwas ist bei uns anders, als in einer
              0815-Bar. Denn anstatt nur langweilig am eigenen Gin Tonic zu
              sippen, haben wir neben zwei Beer Pong-Tischen genügend Platz, an
              denen ihr Flip Cup, Rage Cage oder Chandelier spielen könnt. Wir
              zeigen euch, wie’s geht und ihr werdet ab Sekunde eins merken,
              dass ihr nie wieder ohne Games trinken möchtet. Holt euch an der
              Bar einen Solo Cup und ein Bier und verschafft euch Street
              Credibility. Aber hey, wenn ihr mal keine Lust aufs Gamen habt,
              ihr lieber am eigenen Drink hängen oder die Fights aus sicherer
              Ferne beobachten wollt - dafür ist auch genug Platz.
              <p className="text-right mt-12">
                <GoTo to="howtoplay" hashState="whatToPlay">
                  <span className="relative z-10">HIER GEHT’S ZU</span>
                  <span className="text-highlight white">DEN HOW TO’S.</span>
                </GoTo>
              </p>
            </div>
          </div>
        </section>
        <section id="whoWeAre" className="mt-44">
          <div className="container">
            <div className="header">
              <h1>Who we are.</h1>
            </div>
            <div className="content-padding grid grid-cols-12 lg:gap-x-16 gap-y-16">
              <div className="relative lg:col-span-5 col-span-12">
                <Img
                  className="w-full right-0 filter grayscale"
                  fluid={this.props.data.whoWeAreImg.childImageSharp.fluid}
                  alt="Who We Are Image"
                />
              </div>
              <div className="content-padding lg:col-span-7 col-span-12">
                <div className="md:text-justify text-center text-l">
                  4 Girls – 1 Bar. Ungewöhnlich, ja wissen wir. Und klar, es war
                  eine Idee bei drei Bier, die quasi jeder Freundeskreis schon
                  mal hatte - aber bei uns hieß es: Okay, wir machen’s wirklich.
                  Mit unseren nine to five Jobs bündeln wir alles, was
                  Gründerinnen brauchen: BWL- &amp; Medien-Studium,
                  Projekt-Consulting, PR-Agency, Event- und
                  Marketing-Management, HR Business und psychologische
                  Kriegsführung. Aber nicht nur das. Als Friends from the
                  beginning toppen wir die hard skills mit dem entscheidenden
                  Drive und Urvertrauen ineinander. Ein gutes Bier in Würzburg
                  bekommt ihr an vielen Ecken. Aber wir glauben, da ist mehr.
                  Würzburg braucht einen Spot mit uns hinter der Theke, so
                  you’re welcome!
                  <br />
                  <br /> Anastasia, Lisa, Margarita und Sophia.
                  <br />
                  <br />
                  #sigmawuerzburg #wuerzburg #womensbusiness
                  <p className="mt-12 text-right">
                    <GoTo to="aboutus" hashState="whoWeAre">
                      <span className="z-10 relative">MEHR</span>
                      <span className="text-highlight white">ÜBER UNS</span>
                    </GoTo>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="whatWeDoForOurFuture" className="mt-44">
          <div className="container">
            <div className="header">
              <h1>WHAT WE DO FOR OUR FUTURE.</h1>
            </div>
            <div className="content-padding grid grid-cols-12 lg:gap-x-16 gap-y-16 relative">
              <div className="content-padding lg:col-span-6 col-span-12">
                <div className="md:text-justify text-center text-l">
                  <p>
                    Auch wir setzen uns für eine zukunftsfähige Welt ein, in der
                    nicht nur der Plastikkonsum auf ein Minimum reduziert,
                    sondern auch allgemein bewusster mit Ressourcen umgegangen
                    werden muss. Und ja, wir wissen, genau wir ihr: Plastic
                    sucks.
                  </p>
                  <p>
                    Eine zero waste-Bar ist zwar der Traum, aber schwierig
                    umzusetzen, vor allem als Startup. Wir geben unser Bestes,
                    um euch eine gute Zeit bei uns zu ermöglichen, achten aber
                    auf unsere Emissionen. Um diese Waage zu halten, ergreifen
                    wir Maßnahmen, die unsere Red Cups etwas grüner werden
                    lassen.
                  </p>
                  <p className="mt-12 text-left">
                    <GoTo
                      to="whatwedoforoutfuture"
                      hashState="whatWeDoForOurFuture"
                    >
                      <span className="z-10 relative">KLICK ZU DEN</span>
                      <span className="text-highlight white">MASSNAHMEN.</span>
                    </GoTo>
                  </p>
                </div>
              </div>
              <div className="relative lg:col-span-6 col-span-12">
                <Img
                  className="w-full right-0"
                  fluid={this.props.data.bar.childImageSharp.fluid}
                  alt="Who We Are Image"
                />
              </div>
            </div>
          </div>
        </section> */}

        <section id="whereToFind" className="mt-44">
          <div className="container header">
            <h1>WHERE TO FIND.</h1>
          </div>
          <div className="grid xl:grid-cols-12 grid-cols-1 gap-4 ">
            <div className="text-padding xl:col-start-2 xl:col-span-4">
              <ul className="w-max mx-auto max-w-full">
                <li className="py-6 flex items-center">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="mr-8"
                    size="2x"
                  />
                  <span>Pleicherschulgasse 6, 97070 Würzburg</span>
                </li>
                <li className="py-6 flex items-center">
                  {/* <a href="tel:+4915780334405" className="flex items-center no-underline "> */}
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="mr-8"
                    size="2x"
                  />
                  <span>
                    <a
                      href="tel:+4993140466687"
                      className="text-white cursor-pointer"
                    >
                      0931 – 404 666 87
                    </a>
                    <br />
                    oder slide in unsere Insta-DMs
                  </span>
                  {/* </a> */}
                </li>
                <li className="py-6 flex items-center">
                  <FontAwesomeIcon icon={faClock} className="mr-8" size="2x" />
                  <span>
                    Do. – Sa. 20:00 – 01:00 Uhr <br />
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-padding xl:col-span-2">
              <a
                href="https://goo.gl/maps/yhriVitod3GxWDJ57"
                target="_blank"
                rel="noopener"
              >
                <Img
                  fluid={this.props.data.mapImg.childImageSharp.fluid}
                  className="rounded-full max-w-xs mx-auto"
                  alt="SIGMA Location Google Maps"
                />
              </a>
            </div>
            <div className="text-padding xl:col-span-4">
              <ul className="w-max md:mx-auto max-w-full ml-0">
                <li className="py-6 flex items-center">
                  <a
                    href="mailto:cheers@bar-sigma.de"
                    className="flex items-center no-underline text-white"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="mr-8"
                      size="2x"
                    />
                    <span>cheers@bar-sigma.de</span>
                  </a>
                </li>
                <li className="py-6 flex items-center">
                  <a
                    href="https://www.instagram.com/bar.sigma/"
                    rel="noopener"
                    target="_blank"
                    className="no-underline  flex items-center text-white"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="mr-8"
                      size="2x"
                    />
                    <span>bar.sigma</span>
                  </a>
                </li>
                <li className="py-6 flex items-center">
                  <a
                    href="https://www.tiktok.com/@bar.sigma"
                    rel="noopener"
                    target="_blank"
                    className="no-underline  flex items-center text-white"
                  >
                    <FontAwesomeIcon
                      icon={faTiktok}
                      className="mr-8"
                      size="2x"
                    />
                    <span>@bar.sigma</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default IndexPage;
